body {
	background: #fff;
}

.border.nav-link.border-light.rounded.mr-1 {
	color: #3f729b;
}

.border.nav-link.border-light.rounded.mr-1:hover {
	color: #3374ff;
	background-color: #8ec5fc;
	background-image: -webkit-linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
	background-image: -moz-linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
	background-image: -o-linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
	background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.btn.btn-sm.indigo:hover {
	color: #fff;
}

.nav-link.active {
	background-color: rgba(255, 255, 255, 0.1);
}

#categories > .col-md-4.mb-5 {
	margin-bottom: 8rem !important;
}
.sectionPage {
	position: relative;
	top: -15px;
	margin-bottom: -125px;
}

.sectionPage::after {
	content: '';
	height: 100%;
	width: 100%;
	position: absolute;
	background: url('http://kotamobagukota.go.id/assets/img/footer_.png');
	background-position: center center;
	background-size: cover;
	filter: opacity(0.1);
}
.img-resp {
	width: 8%;
	height: auto;
}
