.nav-pillss .nav-link {
	display: flex;
	background-color: #eeee;
	height: 50px;
	align-items: center;
}
.nav-pillss .nav-link.active,
.nav-pillss .show > .nav-link {
	color: #fff;
	background-color: #ff9a8b;
	background-image: linear-gradient(90deg, #ff9a8b 0%, #ff6a88 55%, #ff99ac 100%);
}

.menu-berger {
	display: none;
	flex-direction: column;
	height: 20px;
	justify-content: space-between;
	position: relative;
}
.menu-berger input {
	position: absolute;
	width: 50px;
	height: 30px;
	opacity: 0;
	left: -9px;
	top: -3px;
	z-index: 3;
}
.menu-berger span {
	display: block;
	width: 30px;
	height: 4px;
	background-color: #ff9a8b;
	transition: all 0.5s;
	z-index: 2;
}

.menu-berger span:nth-child(2) {
	transform-origin: 0 0;
}
.menu-berger span:nth-child(4) {
	transform-origin: 0 100%;
}

.menu-berger input:checked ~ span:nth-child(2) {
	transform: rotate(45deg) translate(-2px, -2px);
}
.menu-berger input:checked ~ span:nth-child(4) {
	transform: rotate(-45deg) translate(-2px, 0);
}
.menu-berger input:checked ~ span:nth-child(3) {
	transform: scale(0);
}

/* responsive */
/* tab */
@media screen and (max-width: 1000px) {
	.menu-berger {
		display: flex;
	}
	.nav-pillss {
		position: absolute;
		flex-direction: column;
		height: 100vh;
		z-index: 1;
		left: 0;
		top: 30px;
		transform: translateX(-150%);
		transition: all 1s;
	}
	.nav-pillss.true {
		transform: translateX(0);
	}
}
