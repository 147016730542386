.map {
	height: 84vh;
	padding: 0;
	margin-top: -2em;
}
.gamps {
	align-items: center;
}
.leaflet-popup .tablemini {
	font-size: 12px;
	width: auto;
	margin-top: 50px;
}
.leaflet-popup .sorting {
	font-size: 12px;
	width: auto;
}
.leaflet-popup .dataTables_filter .form-control {
	margin-left: -60px;
}

.leaflet-popup [data-test~='table-foot'] {
	display: none;
}
.leaflet-popup table.dataTable tbody tr,
.leaflet-popup table.dataTable tbody td {
	padding: 0 0.1rem 0.1rem 0.1rem;
}
.leaflet-popup table.dataTable thead th,
.leaflet-popup table.dataTable thead td {
	padding: 0.6rem 0.5rem 0.5rem 0.5rem;
}
